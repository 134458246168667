import { makeAutoObservable } from 'mobx';
import { Store } from './store';
import { ChatMessage } from './entity';

export class Controller {
  store = new Store();

  constructor() {
    makeAutoObservable(this);
  }

  get getJobsItems() {
    return this.store.jobItem;
  }

  get getJobs() {
    return this.store.jobs;
  }

  get getNewsItem() {
    return this.store.newsItem;
  }

  async fetchNews(item: string) {
    await this.store.getNews(item);
    await this.getNewsTrends(item);
  }

  async fetchJobs(item: string) {
    await this.store.getJobs(item);
  }

  async fetchNewsItems() {
    await this.store.getNewsItem();
  }

  async fetchJobsItems() {
    await this.store.getJobItem();
  }

  async fetchJobsTotalNumber() {
    await this.store.getJobTotal();
  }

  async fetchTopicLists() {
    await this.store.getTopics();
  }


  async getWatch(item: string) {
    await this.store.getWatch(item);
  }

  async getCompany(company: string) {
    if (company == null) {
      return;
    }
    await this.store.getCompanyInfo(company);
  }

  async getCompanyList() {
    await this.store.getCompanies();
  }


  async getNewsTrends(item) {
    await this.store.getNewsTrend(item);
  }

  async fetchVersion() {
    await this.store.getVersion();
  }

  async getStock(stock: string) {
    await this.store.getStockTimeSerie(stock);
  }

  async getStockNews(stock: string) {
    await this.store.getStockNews(stock);
  }

  async getHints() {
    await this.store.getHints();
  }

  async getImportantHints() {
    await this.store.getImportantHints();
  }

  async chat(user_message: ChatMessage) {
    this.store.messages.push(user_message);
    await this.store.chat();
  }


  async saveChat() {
    await this.store.saveChat();
    await this.store.loadChatList();
  }

  async getChats() {
    await this.store.loadChatList();
  }

  async loadChat(id) {
    await this.store.loadChat(id);

  }

  getLatestChatTitle() {
    return this.store.chats[0].name;
  }

  getLatestChatId() {
    return this.store.chats[0].id;
  }

  async getOnboardingItems() {
    await this.store.loadOnBoardingItemsList();
  }
}
