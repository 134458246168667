import { Box, Button, Card, InputBase, styled, Typography } from '@mui/material';
import MarkdownRendererWithHighlighting from './MarkdownRenderer';
import { useDependency } from '../../injections';
import { Controller } from '../../mesh/controller';
import React, { useEffect, useRef, useState } from 'react';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Tesseract from 'tesseract.js';
import { ChromeReaderModeTwoTone, SaveTwoTone } from '@mui/icons-material';

const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
    background: ${theme.colors.primary.main};
    color: ${theme.palette.primary.contrastText};
    padding: ${theme.spacing(2)};
    border-radius: ${theme.general.borderRadiusXl};
    border-top-right-radius: ${theme.general.borderRadius};
`
);

const CardWrapperSecondary = styled(Card)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[10]};
    color: ${theme.colors.alpha.black[100]};
    padding: ${theme.spacing(2)};
    border-radius: ${theme.general.borderRadiusXl};
    border-top-left-radius: ${theme.general.borderRadius};
`
);

const MessageInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(1)};
    width: 100%;
`
);

const DropZoneOverlay = styled(Box)(
  ({ theme }) => `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${theme.typography.pxToRem(24)};
    pointer-events: none;
`
);

interface ChatContentProps {
  updateSignal?: any;
}

function ChatContent({ updateSignal }: ChatContentProps) {

  useEffect(() => {
  }, [updateSignal]);
  const c = useDependency<Controller>(Controller);

  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [, forceUpdate] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const dragCounter = useRef(0);

  const inputRef = useRef<null | HTMLInputElement>(null);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSend = async () => {
    if (loading || input.trim() === '') return;
    setLoading(true);

    const message = {
      role: 'user',
      content: input
    };
    await c.chat(message);
    setInput('');
    setLoading(false);
    scrollToBottom();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSave = async () => {
    await c.saveChat();
    const id = c.getLatestChatId();
    const title = c.getLatestChatTitle();

    c.store.activeChatId = id;
    c.store.activeChatName = title;
  };

  const handleClear = () => {
    c.store.messages = []; // Chat zurücksetzen
    c.store.activeChatId = undefined;
    c.store.activeChatName = 'Neuer Chat';
    c.store.addDataToChat = false;
    setInput(''); // Eingabefeld zurücksetzen
    forceUpdate(n => n + 1); // forcing re-render
    scrollToBottom(); // Nach unten scrollen
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKnowledgeChat = () => {
    c.store.activeChatName = 'Neuer intelligenter Chat';
    c.store.messages = [];
    c.store.activeChatId = undefined;
    c.store.addDataToChat = true;
    setInput('');
    forceUpdate(n => n + 1);
    scrollToBottom();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleImageUpload = async (file) => {
    setLoading(true);
    try {
      const { data: { text } } = await Tesseract.recognize(file, 'eng');
      setInput(text);
    } catch (error) {
      console.error('OCR Fehler:', error);
    }
    setLoading(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    dragCounter.current = 0;
    if (loading) return;

    const files = e.dataTransfer.files;
    if (files && files.length) {
      handleImageUpload(files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    dragCounter.current++;
    if (dragCounter.current === 1) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.startsWith('image/')) {
        const file = item.getAsFile();
        if (file) {
          handleImageUpload(file);
        }
      }
    }
  };

  return (
    <Box
      position="relative" // Notwendig für den Overlay
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {isDragging && (
        <DropZoneOverlay>
          Bilder hier ablegen
        </DropZoneOverlay>
      )}

      <Box p={3} sx={{ height: '75vh', overflowY: 'auto' }}>
        {c.store.messages.map((message, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="flex-start"
            justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'}
            py={3}
          >
            <Box
              display="flex"
              alignItems={message.role === 'user' ? 'flex-end' : 'flex-start'}
              flexDirection="column"
              justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'}
              mr={2}
              ml={2}
            >
              {message.role === 'user' ? (
                <CardWrapperPrimary>
                  <MarkdownRendererWithHighlighting markdown={message.content} />
                </CardWrapperPrimary>
              ) : (
                <CardWrapperSecondary>
                  <MarkdownRendererWithHighlighting markdown={message.content} />
                </CardWrapperSecondary>
              )}
              <Typography
                variant="subtitle1"
                sx={{
                  pt: 1,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
              </Typography>
            </Box>
          </Box>
        ))}
        <div ref={messagesEndRef}></div>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1
        }}
      >
        <Box flexGrow={1} display="flex">
          <MessageInputWrapper
            autoFocus
            placeholder=" Schreiben Sie hier Ihre Nachricht oder Bild einfügen"
            onChange={(e) => setInput(e.target.value)}
            value={input}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            disabled={loading}
            multiline
            minRows={3}
            maxRows={20}
            inputRef={inputRef}
          />
        </Box>
        <Box flexDirection="row">
          <Box>
            <Button onClick={handleSend} startIcon={<SendTwoToneIcon />} variant="contained" disabled={loading}>
              {loading ? 'Senden...' : 'Senden'}
            </Button>
            <Button onClick={handleSave} startIcon={<SaveTwoTone />} variant="contained" color="secondary"
                    disabled={loading}>
              Speichern
            </Button>
            <Button onClick={handleClear} startIcon={<DeleteTwoToneIcon />} variant="contained" color="secondary"
                    disabled={loading}>
              Löschen
            </Button>
          </Box>
          <Box>
            <Button onClick={handleKnowledgeChat} startIcon={<ChromeReaderModeTwoTone />} variant="contained"
                    color="secondary"
                    disabled={loading}>
              Intelligenter Chat
            </Button>

          </Box>
        </Box>

      </Box>
    </Box>
  );
}

export default ChatContent;