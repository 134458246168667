import {
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  CardHeader, Chip, Pagination, Select, InputAdornment, IconButton
} from '@mui/material';
import { News } from '../../../mesh/entity';
import { observer } from 'mobx-react';
import { useDependency } from '../../../injections';
import { Controller } from '../../../mesh/controller';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

function MeshNewsAC() {
  const c = useDependency<Controller>(Controller);

  const ITEMS_PER_PAGE = 20;
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [item, setItem] = useState(localStorage.getItem('newsItem'));
  const count = Math.ceil(c.store.filteredNews.length / ITEMS_PER_PAGE);
  const [lastItem, setLastItem] = useState('');

  useEffect(() => {
    if (c.store.version == '') {
      c.fetchVersion();
    }

    setLastItem(item);

    if (item == 'null') {
      setItem('latest');
      localStorage.setItem('newsItem', 'latest');

    }

    if (c.getJobs.length === 0) {
      c.fetchJobs('hot');
      c.store.filteredJobs = c.getJobs.filter((job) => job.active);
    }

    if (c.getNewsItem.length === 0) {
      c.fetchNewsItems();
    }

    if (item != 'none' && item != lastItem) {
      c.fetchNews(item);
    }


  }, [c.store.newsItem, item, c.store.version]);

  useEffect(() => {
    filter();
  }, [searchString]);


  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleItemChange = (event) => {
    c.store.newsTrends = '';
    setItem(event.target.value);
    localStorage.setItem('newsItem', event.target.value);
  };

  const getDataForCurrentPage = () => {
    const start = (page - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return c.store.filteredNews.slice(start, end).sort(function(a: News, b: News) {
      return moment(b.published_date).diff(moment(a.published_date));
    });
  };

  function filter() {
    c.store.filteredNews = c.store.news
      .sort(function(a: News, b: News) {
        return moment(b.published_date).diff(moment(a.published_date));
      })
      .filter((news) =>
        (news.description.toLowerCase().includes(searchString.toLowerCase())
          || news.short_desc.toLowerCase().includes(searchString.toLowerCase())
          || news.title.toLowerCase().includes(searchString.toLowerCase()))
        || searchString == '');
  }

  function truncateString(str: string, num: number): string {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pb: 3 }}>
        <Typography variant="h3">News ({c.store.filteredNews.length})</Typography>
        <Pagination count={count} page={page} onChange={handleChange} />
      </Box>
      <Box display="flex" style={{ marginBottom: '10px' }}>

        <Select
          name={'item'}
          defaultValue={item}
          onChange={handleItemChange}
          style={{ margin: '5px' }}
        >

          <MenuItem key={'latest'} value={'latest'}>Letzten 2 Tage</MenuItem>

          {Object.keys(c.getNewsItem)
            .slice().sort()
            .map((item) => {
              return <MenuItem key={item} value={item}>{item}</MenuItem>;
            })
          }


        </Select>

        <TextField
          label="Suchtext"
          name="string"
          onChange={(e) => setSearchString(e.target.value)}
          style={{ margin: '5px' }}
          fullWidth={true}
        />


      </Box>

      <Box>
        {c.getNewsItem[item] && <h4>Themen:
          {c.getNewsItem[item]
            .slice().sort()
            .map((item, index, array) => {
              return (index === array.length - 1) ? ' ' + item : ' ' + item + ',';
            })
          }

        </h4>}
      </Box>
      {c.store.news && (
        <Grid container spacing={4}>

          {getDataForCurrentPage()
            .map((news: News) => {
                let publisher = '';
                if (news.item == 'BaFin Nachrichten'
                  || news.item == 'BaFin Konsultation'
                  || news.item == 'BaFin Newspapers'
                ) {
                  publisher = news.publisher;
                } else {
                  try {
                    publisher = JSON.parse(news.publisher
                      .replaceAll('\'', '"')).title;
                  } catch (error){
                    publisher = ''
                  }

                }

                return (

                  < Grid lg={3} item key={news.id}>
                    <Card
                      style={{ minHeight: '500px' }}>
                      <CardHeader
                        title={<Grid>
                          <Box>

                            {news.img &&
                              <img src={news.img} alt="IMAGE"
                                   style={{ height: '200px', width: '100%', objectFit: 'cover' }} />
                            }
                            {!news.img &&
                              <Box style={{ display: 'block', textAlign: 'center' }}>
                                <img src={'static/images/mesh/no.png'} alt="IMAGE"
                                     style={{ height: '200px', width: '80%' }} />

                              </Box>
                            }
                          </Box>

                          <Box display="flex" style={{ marginTop: '10px' }}>
                            <Box flex={12}>
                              <a target="_blank" rel="noreferrer" href={news.url}>
                                {truncateString(news.title, 120)}
                              </a>
                            </Box>

                            <Box>
                              <Chip key={'S_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}
                                    label={news.search_item} color="warning" />
                            </Box>
                          </Box>
                        </Grid>
                        }
                        subheader={
                          publisher + ' - ' + moment(news.published_date).format('DD.MM.YYYY')
                        }
                      />

                      <CardContent>
                        <Grid display="flex" flexDirection={'column'} style={{ justifyContent: 'space-between' }}>

                          <Box>
                            {truncateString(news.short_desc, 250)}
                          </Box>

                          <Box style={{ marginTop: '10px' }}>
                            {news.keywords.length > 2 && news.keywords.replace(']', '')
                              .replace('[', '')
                              .split(',')
                              .slice(0, 3)
                              .map((topic: string) =>
                                <Chip key={'B_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}

                                      label={topic
                                        .replaceAll('\'', '')
                                      }
                                      style={{ margin: '5px' }} color="primary" />
                              )}

                          </Box>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              }
            )}
          <Box display="flex" alignItems="flex-end" sx={{ pb: 3 }} width={'100%'}>
            <Box flex={10}></Box>
            <Pagination count={count} page={page} onChange={handleChange} />
          </Box>
        </Grid>
      )}
    </>
  );
}


export const MeshNews = observer(MeshNewsAC);
