import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { Api } from '../utils/ApiService';
import {
  ChatMessage,
  CompanyWatch,
  Important,
  Job,
  News,
  NewsItem,
  OnBoardingItem,
  Stock,
  StockApiResponse,
  Watch,
  X1F
} from './entity';
import moment from 'moment/moment';
import { Helper } from '../utils/helper';

export class Store {

  constructor() {
    makeAutoObservable(this);
  }

  helper = new Helper();

  endpointNews = this.helper.getBaseUrl + `/api/news`;

  endpointJobs = this.helper.getBaseUrl + `/api/jobs`;

  endpointWatch = this.helper.getBaseUrl + `/api/topic`;

  endpointCompany = this.helper.getBaseUrl + `/api/company`;

  endpointStock = this.helper.getBaseUrl + `/api/stock`;

  endpointChat = this.helper.getBaseUrl + `/api/chat`;

  api = new Api();

  jobs: Job[] = [];

  news: News[] = [];

  filteredJobs: Job[] = [];

  filteredNews: News[] = [];

  stockNews: News[] = [];

  jobItem: string[] = [];

  newsItem: NewsItem[] = [];

  newsTrends: string = '';

  watch: Watch;

  companyItem: string = '';

  companies: string[] = [];

  x1f: X1F[] = [];

  companyWatch: CompanyWatch;

  selectedCompany: string = '';

  trendTopicsName: string[] = [];

  hotTopicsName: string[] = [];

  total: string = '0';

  version: string = '';

  importantHints: Important[] = [];

  stockHints: Stock[] = [];

  stockData: StockApiResponse;

  messages: ChatMessage[] = [];

  chats = [];

  onboardingItems: OnBoardingItem[] = [];

  activeChatId = undefined;

  activeChatName = 'Neuer Chat';

  addDataToChat;


  getJobs = async (item: string) => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointJobs + '/' + item.replace('/', ' ')}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.jobs = Object.values(toJS(response));
      this.filteredJobs = Object.values(toJS(response));
    });
  };

  getNews = async (item: string) => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointNews + '/' + item}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.news = Object.values(toJS(
        response.sort(function(a: News, b: News) {
          return moment(b.published_date).diff(moment(a.published_date));
        })
      ));
      this.filteredNews = this.news;
    });
  };

  getNewsItem = async () => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointNews + `/items`}`,
      method: 'GET'
    });
    runInAction(() => {
      this.newsItem = response;
    });
  };

  getJobItem = async () => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointJobs + `/items`}`,
      method: 'GET'
    });

    runInAction(() => {
      this.jobItem = response;
    });
  };


  getJobTotal = async () => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointJobs + `/total`}`,
      method: 'GET'
    });

    runInAction(() => {
      this.total = response;
    });
  };

  getTopics = async () => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointJobs + `/topics`}`,
      method: 'GET'
    });

    runInAction(() => {
      this.hotTopicsName = response['hot'];
    });
  };


  async getNewsTrend(item) {
    const response = await this.api.fetch({
      endpoint: `${this.endpointNews + '/trend/' + item}`,
      method: 'GET'
    });
    runInAction(() => {
      this.newsTrends = response;
    });
  }

  getWatch = async (item: string) => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointWatch + '/info/' + item}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.companyItem = item;
      this.watch = response;
    });
  };

  getCompanies = async () => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointCompany + '/list'}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.companies = response.companies;
    });
  };

  getCompanyInfo = async (company: string) => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointCompany + '/' + company}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.companyWatch = response;
      this.selectedCompany = company;
    });
  };

  async getVersion() {
    const response = await this.api.fetch({
      endpoint: `${this.helper.getBaseUrl}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.version = response.version;
    });
  };


  async getHints() {
    const response = await this.api.fetch({
      endpoint: `${this.endpointStock}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.stockHints = response;
    });
  };

  async getStockNews(stock) {
    const response = await this.api.fetch({
      endpoint: `${this.endpointStock + '/news/' + stock}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.stockNews = response;
    });
  };

  async getImportantHints() {
    const response = await this.api.fetch({
      endpoint: `${this.endpointStock + '/important'}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.importantHints = response;
    });
  };

  async getStockTimeSerie(name: string) {
    const response = await this.api.fetch({
      endpoint: `${this.endpointStock + '/' + name}`,
      method: 'GET'
    });

    if (response.length == 0) {
      this.stockData = null;
    }

    runInAction(() => {
      this.stockData = response;
    });
  };

  async chat() {
    try {
      const gpt_response = await this.api.fetch({
        endpoint: `${this.endpointChat + '/' + this.addDataToChat}`,
        method: 'POST',
        body: JSON.stringify({ 'messages': this.messages })
      });

      if (gpt_response.length === 0) {
        return;
      }

      runInAction(() => {
        this.messages.push(gpt_response);
      });
    } catch (error) {
      if (error.response && error.response.status === 500) {
        runInAction(() => {
          this.messages.push({
            role: 'System',
            content: 'Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
          });
        });
      } else {
        console.error('Unexpected error:', error);
      }
    }
  }


  async saveChat() {
    await this.api.fetch({
      endpoint: `${this.endpointChat + '/save/' + this.activeChatId}`,
      method: 'POST',
      body: JSON.stringify({ 'messages': this.messages })
    });
  }

  async loadChat(id: string) {
    const response = await this.api.fetch({
      endpoint: `${this.endpointChat + '/load/' + id}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.messages = response;
    });
  };

  async loadChatList() {
    const response = await this.api.fetch({
      endpoint: `${this.endpointChat + '/show'}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.chats = response;
    });
  };

  async loadOnBoardingItemsList() {
    const response = await this.api.fetch({
      endpoint: `${this.endpointChat + '/onboardingItems'}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.onboardingItems = response;
    });
  };
}
