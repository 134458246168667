import React, { useState } from 'react';
import { Button, Card, CardContent, CardHeader, Container, Divider, Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Helper } from '../../../utils/helper';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';


const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Zustand für Fehler

  const helper = new Helper();
  const endpointLogin = helper.getBaseUrl + `/api/login`;
  const endpointLoginAzure = helper.getBaseUrl + `/api/login/azure`;

  const handleLogin = async () => {
    setErrorMessage(null); // Fehler zurücksetzen

    try {
      const response = await fetch(endpointLogin, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });
      if (!response.ok) {
        throw new Error('Login fehlgeschlagen');
      }
      const data = await response.json();
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('azure', 'false');

      if (username == 'm.thimm@beckmann-partner.de') {
        localStorage.setItem('azure', 'true');
      }

      window.location.href = '/'; // Gehe zur Login-Seite
    } catch (error) {
      setErrorMessage('Benutzername oder Passwort ist falsch.');
    }
  };

  const handleAzureLogin = async (token) => {
    setErrorMessage(null);

    try {
      const response = await fetch(endpointLoginAzure, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
      });
      if (!response.ok) {
        throw new Error('Login fehlgeschlagen');
      }
      const data = await response.json();
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('azure', 'true');
      window.location.href = '/'; // Gehe zur Login-Seite
    } catch (error) {
      setErrorMessage('Benutzername oder Passwort ist falsch.');
    }
  };

  const msalConfig = {
    auth: {
      clientId: 'fbedf896-56bb-42c5-a526-e8f4e1b6d9c8',
      authority: 'https://login.microsoftonline.com/afb3882e-7bb6-4263-b874-b16ac1b55604',
      redirectUri: 'https://consultants-cockpit.com/login'
    }
  };

  const pca = new PublicClientApplication(msalConfig);

  const LoginButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
      instance.loginPopup({
        scopes: ['User.Read.All']
      }).then((response) => {
        console.log('Login erfolgreich:', response);
        handleAzureLogin(response);
      }).catch((response) => console.error('Login fehlgeschlagen:', response));
    };

    return <Button onClick={handleLogin} variant="outlined" size={'small'} style={{ marginTop: '15px' }}>
      Login via <br />Beckmann & Partner Account (Azure)</Button>;
  };


  return (
    <>
      <Container maxWidth="md" style={{ marginTop: '10%' }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >

          <Grid item xs={12}>
            <Card>
              <CardHeader title="Herzlich Willkommen" />
              <Divider />
              <CardContent style={{ textAlign: 'center' }}>
                {errorMessage && ( // Fehlermeldung anzeigen, falls vorhanden
                  <Typography color="error" style={{ marginBottom: '15px' }}>
                    {errorMessage}
                  </Typography>

                )}
                <MsalProvider instance={pca}>
                  <LoginButton />
                </MsalProvider>
                <div>
                  -- oder --
                </div>
                <TextField
                  label="Benutzername"
                  name="string"
                  onChange={(e) => setUsername(e.target.value)}
                  style={{ margin: '5px' }}
                  value={username}
                />

                <TextField
                  label="Passwort"
                  name="Passwort"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ margin: '5px' }}
                  value={password}
                />

                <Button onClick={handleLogin} variant="outlined" size={'small'}
                        style={{ marginTop: '15px' }}>Einloggen</Button>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </Container>
    </>
  );
};

export default Login;