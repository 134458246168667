import { Box, Button, Card, CardContent, CardHeader, Chip, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useDependency } from '../../../injections';
import { Controller } from '../../../mesh/controller';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { News } from '../../../mesh/entity';
import moment from 'moment/moment';


function MeshCompanyAC() {
  const c = useDependency<Controller>(Controller);
  const [company, setCompany] = useState(localStorage.getItem('company'));


  useEffect(() => {
    if (company != '' && c.store.selectedCompany != company) {
      c.getCompany(company);
    }

    if (c.store.companies.length == 0) {
      c.getCompanyList();
    }
    if (!company) {
      setCompany('Atruvia');
    }


  }, [c.store.companyWatch, c.store.companies, company]);

  function truncateString(str: string, num: number): string {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  function handleClick(company: string) {
    setCompany(company);
    localStorage.setItem('company', company);
  }


  return (
    <>
      {c.store.companies && (
        <Grid lg={12} item key={'Topic'} display={'flex'}>
          <Box flex={2} sx={{ p: 2 }}>
            <Card>
              <CardHeader
                title={
                  'Companies'
                }
              />
              <CardContent>
                <Grid>
                  <ul style={{ listStyle: 'none' }}>
                    {c.store.companies
                      .slice()
                      .sort()
                      .map((c: string) => {
                          return <li key={c}>
                            <Button className={`${company === c ? 'Mui-selected' : ''}`}
                                    onClick={() => handleClick(c)}>{c.trim()}</Button>
                          </li>;
                        }
                      )}
                  </ul>
                </Grid>
                <Typography variant="h6" gutterBottom>
                </Typography>
              </CardContent>
            </Card>
          </Box>

          {c.store.companyWatch && (
            <Grid flex={10}>
              <h2 style={{ marginLeft: '10px' }}>{company}</h2>

              <Grid display={'flex'}>

                <Grid flex={1} sx={{ p: 2 }}>
                  <Card sx={{ height: '400px', display: 'flex', flexDirection: 'column' }}>
                    <CardHeader
                      title={
                        'Statistiken:'
                      }
                    />
                    <CardContent sx={{ flex: 1, overflowY: 'auto' }}>
                      <Grid container>
                        <Box style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          marginTop: '7%'
                        }}>
                          <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginBottom: '16px'
                          }}>
                            <div><b>Offene interessante Stellen (Neu):</b></div>
                            <div style={{ marginLeft: '30px' }}>{c.store.companyWatch.active_recent_job_count}</div>
                          </div>
                          <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginBottom: '16px'
                          }}>
                            <div><b>Offene interessante Stellen (Alt):</b></div>
                            <div style={{ marginLeft: '30px' }}>{c.store.companyWatch.active_old_job_count}</div>
                          </div>
                          <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginBottom: '16px'
                          }}>
                            <div><b>Stellen die schnell besetzt wurden:</b></div>
                            <div style={{ marginLeft: '30px' }}>{c.store.companyWatch.not_active_recent_job_count}</div>
                          </div>

                          <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginBottom: '16px'
                          }}>
                            <div><b>Anzahl bekannter Personen bei Unternehmen:</b></div>
                            <div style={{ marginLeft: '30px' }}>{c.store.companyWatch.user_count}</div>
                          </div>
                          <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginBottom: '16px'
                          }}>
                            <div><b>Davon in Social Media sehr aktiv sind:</b></div>
                            <div style={{ marginLeft: '30px' }}>{c.store.companyWatch.power_user_count}</div>
                          </div>
                        </Box>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid flex={2} sx={{ p: 2 }}>
                  <Card sx={{ height: '400px', display: 'flex', flexDirection: 'column' }}>
                    <CardHeader
                      title={
                        'Aktuelle Trends'
                      }
                    />
                    <CardContent sx={{ flex: 1, overflowY: 'auto' }}>
                      <Grid container>
                        <Box>
                          <ReactMarkdown>
                            {c.store.companyWatch.trend}
                          </ReactMarkdown>
                        </Box>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid display={'flex'}>

                <Grid flex={1} sx={{ p: 2 }}>
                  <Card sx={{ height: '400px', display: 'flex', flexDirection: 'column' }}>
                    <CardHeader
                      title={
                        'Aktuell intessante Technologien'
                      }
                    />
                    <CardContent sx={{ flex: 1, overflowY: 'auto' }}>
                      <Grid container>
                        <Box>
                          <ReactMarkdown>
                            {c.store.companyWatch.skills_recent}
                          </ReactMarkdown>
                        </Box>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>

                <Grid flex={2} sx={{ p: 2 }}>
                  <Card sx={{ height: '400px', display: 'flex', flexDirection: 'column' }}>
                    <CardHeader
                      title={
                        'Aktuelle Informationen'
                      }
                    />
                    <CardContent sx={{ flex: 1, overflowY: 'auto' }}>
                      <Grid container>
                        <Box>
                          <ReactMarkdown>
                            {c.store.companyWatch.tech_infos}
                          </ReactMarkdown>
                        </Box>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid display={'flex'}>

                <Grid flex={1} sx={{ p: 2 }}>
                  <Card sx={{ height: '400px', display: 'flex', flexDirection: 'column' }}>
                    <CardHeader
                      title={
                        'Gesamtübersicht: Skills und Technologien'
                      }
                    />
                    <CardContent sx={{ flex: 1, overflowY: 'auto' }}>
                      <Grid container>
                        <Box>
                          <ReactMarkdown>
                            {c.store.companyWatch.skills_old}
                          </ReactMarkdown>
                        </Box>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>

                <Grid flex={2} sx={{ p: 2 }}>
                  <Card sx={{ height: '400px', display: 'flex', flexDirection: 'column' }}>
                    <CardHeader
                      title={
                        'Benchmark-Analyse'
                      }
                    />
                    <CardContent sx={{ flex: 1, overflowY: 'auto' }}>
                      <Grid container>
                        <Box>
                          <ReactMarkdown>
                            {c.store.companyWatch.benchmark}
                          </ReactMarkdown>
                        </Box>
                      </Grid>

                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

    </>
  );
}


export const MeshCompany = observer(MeshCompanyAC);
