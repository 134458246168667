import { Box, Button, Card, CardContent, CardHeader, Chip, Grid, Pagination, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useDependency } from '../../../injections';
import { Controller } from '../../../mesh/controller';
import React, { useEffect, useState } from 'react';
import { Hint, Important, News, Stock } from '../../../mesh/entity';
import moment from 'moment/moment';
import CandleChart from './CandleChart';


function extracted_latest_hints(recommendation: string) {
  const lines = recommendation.split('$$');
  const hints = [];
  lines.map((line, index) => {
    const [col1, col2, col3, col4, col5] = line.split(';').map(part => part.trim());
    let hint: Hint = {
      title: col4,
      published_date: col5,
      publisher: col1,
      url: col3,
      recommendation: col2
    };
    hints.push(hint);
  });

  const sortedHints = hints
    .sort((a, b) => {
      if (a.publisher > b.publisher) return 1;
      if (a.publisher < b.publisher) return -1;
      return new Date(b.published_date).getTime() - new Date(a.published_date).getTime();
    });

  const latestHintsByPublisher = new Map<string, Hint>();

  for (const hint of sortedHints) {
    if (!latestHintsByPublisher.has(hint.publisher)) {
      latestHintsByPublisher.set(hint.publisher, hint);
    }
  }

  const latestHints = Array.from(latestHintsByPublisher.values());
  return latestHints;
}

function MeshStockAC() {
  const c = useDependency<Controller>(Controller);

  const ITEMS_PER_PAGE = 10;
  const [lastStock, setLastStock] = useState('');
  const [stock, setStock] = useState('');
  const [page, setPage] = useState(1);
  const count = Math.ceil(c.store.stockHints.length / ITEMS_PER_PAGE);
  const greenList = ['buy', 'kaufen', 'outperform', 'overweight', 'add'];
  const grayList = ['neutral', 'market-perform', 'hold', 'sector', 'halten'];
  const redList = ['sell', 'verkaufen', 'underweight'];
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (c.store.importantHints.length == 0) {
      c.getHints();
      c.getImportantHints();
    }
  }, [c.store.importantHints]);

  useEffect(() => {
    if (stock != lastStock) {
      c.getStock(stock);
      c.getStockNews(stock);
      c.getHints();
      c.getImportantHints();
    }
    setLastStock(stock);
  }, [stock]);


  function truncateString(str: string, num: number): string {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  const countRecommendations = (hints: Hint[]): { green: number; gray: number; red: number } => {
    let counts = { green: 0, gray: 0, red: 0 };

    for (let hint of hints) {
      const recLower = hint.recommendation.toLowerCase();

      if (greenList.some(word => recLower.includes(word.toLowerCase()))) {
        counts.green++;
      } else if (grayList.some(word => recLower.includes(word.toLowerCase()))) {
        counts.gray++;
      } else if (redList.some(word => recLower.includes(word.toLowerCase()))) {
        counts.red++;
      }
    }
    return counts;
  };

  const RecommendationCounter = (recommendation: string) => {

    const latestHints = extracted_latest_hints(recommendation);

    const counts = countRecommendations(latestHints);
    const highestCount = Math.max(counts.green, counts.gray, counts.red);

    function getColorClass(count, baseClass) {
      return count === highestCount ? `${baseClass} highlight` : '';
    }

    return (
      <div>
        <p className={getColorClass(counts.green, 'green')}>Kaufen: {counts.green}</p>
        <p className={getColorClass(counts.gray, 'gray')}>Halten: {counts.gray}</p>
        <p className={getColorClass(counts.red, 'red')}>Verkaufen: {counts.red}</p>
      </div>
    );
  };

  const getRecommendationColor = (recommendation: string): string => {
    if (recommendation == null){
      return 'gray'
    }

    const lowerCaseRecommendation = recommendation.toLowerCase();
    if (greenList.some(word => lowerCaseRecommendation.trim().includes(word))) {
      return 'green';
    } else if (grayList.some(word => lowerCaseRecommendation.includes(word))) {
      return 'darkslategray';
    } else if (redList.some(word => lowerCaseRecommendation.includes(word))) {
      return 'red';
    }
    return 'gray';
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  function commaSeparatedText(text: string) {
    const latestHints = extracted_latest_hints(text);

    return (
      <table>
        <tbody>
        {latestHints.map((hint, index) => {
          return (
            <tr key={index}>
              <td style={{ width: '200px' }}>{hint.publisher}</td>
              <td style={{ width: '150px', color: getRecommendationColor(hint.recommendation) }}>
                <b>{hint.recommendation}</b>
              </td>
              <td style={{ width: '150px' }}>{hint.published_date}</td>
              <td style={{ width: '250px' }}>{hint.title}</td>
              <td style={{ width: '100px', textAlign: 'center' }}>
                <a target="_blank" rel="noreferrer" href={hint.url}> link </a>
              </td>
            </tr>

          );
        })}
        </tbody>
      </table>
    );
  }

  function handleClick(stock: string) {
    setStock(stock);
  }

  const getDataForCurrentListPage = () => {
    const start = (page - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return c.store.stockHints.slice(start, end).sort(function(a: Stock, b: Stock) {
      return moment(b.published_date).diff(moment(a.published_date));
    });
  };

  const toggleRow = (name: string) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  };

  return (
    <>
      <Grid lg={12} item key={'Topic'} display={'flex'}>
        <Box flex={8}>
          <Card sx={{ m: 1 }}>
            <CardHeader
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ color: 'gray' }}>
                    Disclaimer:
                    <br />
                    Die bereitgestellten Informationen stellen keine Handelsvorschläge oder Finanzberatung dar. Jegliche
                    Investitionsentscheidungen, die auf diesen Informationen basieren, erfolgen auf eigenes Risiko. Der
                    Handel mit Wertpapieren birgt Risiken, und Sie sollten diese vollständig verstehen, bevor Sie
                    Investitionen tätigen.
                  </div>
                  <img
                    src="/static/images/mesh/ausrufezeichen.png"
                    alt="Ausrufezeichen"
                    style={{ width: '48px', height: '48px', marginLeft: '30px', marginRight: '50px' }}
                  />
                </div>
              }
            />
          </Card>
        </Box>
      </Grid>
      <Grid lg={12} item key={'Topic'} display={'flex'}>
        <Box flex={8}>
          <Card sx={{ m: 1 }}>
            <CardHeader
              title={
                'Am meisten erwähnte Aktien der letzten Woche'
              }
            />
            <CardContent>
              <Grid>
                <table>
                  <thead>
                  <tr>
                    <th>Aktie</th>
                    <th>Symbol</th>
                    <th>Empfehlungen</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {c.store.importantHints
                    .slice()
                    .sort((a: Important, b: Important) => Number(b.count) - Number(a.count))
                    .map((important: Important) => (
                      <React.Fragment key={important.name}>
                        <tr style={{ borderBottom: '1px solid black' }}>
                          <td style={{ width: '20%', textAlign: 'center' }}>
                            <Button
                              className={`${stock === important.name ? 'Mui-selected' : ''}`}
                              onClick={() => handleClick(important.name)}
                            >
                              {important.name}
                            </Button>
                          </td>
                          <td style={{ width: '20%', textAlign: 'center' }}>{important.symbol}</td>
                          <td style={{
                            width: '20%',
                            textAlign: 'center'
                          }}>{RecommendationCounter(important.recommendations)}</td>
                          <td style={{ width: '15%' }}>
                            <Button onClick={() => toggleRow(important.name)}>
                              {expandedRows[important.name] ? 'Details ausblenden' : 'Details anzeigen'}
                            </Button>
                          </td>
                        </tr>
                        {expandedRows[important.name] && (
                          <tr>
                            <td colSpan={4} style={{ padding: '10px' }}>
                              {commaSeparatedText(important.recommendations)}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  }
                  </tbody>
                </table>
              </Grid>
              <Typography variant="h6" gutterBottom>
              </Typography>
            </CardContent>
          </Card>

          <Card sx={{ m: 1 }}>
            <CardHeader
              title={
                'Aktien Analysten Meinungen'
              }
            />
            <CardContent>
              <Pagination count={count} page={page} onChange={handleChange} />
              <br />
              <Grid container>

                <table>
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th>Symbol</th>
                    <th>Publisher</th>
                    <th>Recommendation</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {getDataForCurrentListPage().map((s: Stock, index: number) => (
                    <tr key={index}>
                      <td style={{ width: '150px' }}><Button className={`${stock === s.name ? 'Mui-selected' : ''}`}
                                                             onClick={() => handleClick(s.name)}>{s.name}</Button></td>
                      <td style={{ width: '100px' }}>{s.symbol}</td>

                      <td>{s.publisher}</td>
                      <td style={{ color: getRecommendationColor(s.recommendation) }}>
                        <b>{s.recommendation}</b>
                      </td>
                      <td style={{ width: '100px' }}>{s.published_date.substring(0, 10)}</td>
                      <td style={{ padding: '5px' }}>{s.title}</td>
                      <td>
                        <a target="_blank" rel="noreferrer" href={s.url}>
                          link
                        </a>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </Grid>

            </CardContent>
          </Card>
        </Box>

        <Grid flex={6}>
          <h2 style={{ marginLeft: '10px' }}>{stock}</h2>

          <Grid display={'flex'}>

            <Grid flex={8}>

              <Card sx={{ m: 1 }}>
                <CardContent>
                  {c.store.stockData ? (<CandleChart stockData={c.store.stockData} />) : (<p>Wähle eine Aktie aus</p>)}
                </CardContent>
              </Card>


              <Card sx={{ m: 1 }}>
                <CardHeader
                  title={
                    'News: ' +
                    c.store.stockNews
                      .length
                  }
                />
                <CardContent>
                  <Grid container spacing={2} style={{ maxHeight: '750px', overflow: 'auto' }}>
                    {c.store.stockNews
                      .map((news: News) => {
                          let publisher = JSON.parse(news.publisher
                            .replaceAll('\'', '"')).title;

                          return (
                            < Grid lg={12} item key={news.id}>
                              <Card>
                                <CardHeader
                                  title={<Grid>
                                    <Box>
                                      {news.img &&
                                        <img src={news.img} alt="IMAGE"
                                             style={{
                                               height: '200px',
                                               width: '100%',
                                               objectFit: 'cover'
                                             }} />
                                      }
                                      {!news.img &&
                                        <Box style={{ display: 'block', textAlign: 'center' }}>
                                          <img src={'static/images/mesh/no.png'} alt="IMAGE"
                                               style={{ height: '200px', width: '80%' }} />

                                        </Box>
                                      }
                                    </Box>

                                    <Box display="flex" style={{ marginTop: '10px' }}>
                                      <Box flex={12}>
                                        <a target="_blank" rel="noreferrer" href={news.url}>
                                          {truncateString(news.title, 120)}
                                        </a>
                                      </Box>

                                      <Box>
                                        <Chip key={'S_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}
                                              label={news.search_item} color="warning" />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  }
                                  subheader={

                                    publisher + ' - ' + moment(news.published_date).format('DD.MM.YYYY')
                                  }
                                />

                                <CardContent>
                                  <Grid display="flex" flexDirection={'column'}
                                        style={{ justifyContent: 'space-between' }}>

                                    <Box>
                                      {truncateString(news.short_desc, 250)}
                                    </Box>

                                    <Box style={{ marginTop: '10px' }}>

                                      {news.trend_hits && (
                                        news.trend_hits.slice(1, -1).split(',')
                                          .map((trend: string) =>
                                            <Chip
                                              key={'J_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}
                                              label={trend}
                                              style={{ margin: '5px' }} color="success" />
                                          ))}

                                      {news.keywords.length > 2 && news.keywords.replace(']', '')
                                        .replace('[', '')
                                        .split(',')
                                        .slice(0, 3)
                                        .map((topic: string) =>
                                          <Chip
                                            key={'B_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}

                                            label={topic
                                              .replaceAll('\'', '')
                                            }
                                            style={{ margin: '5px' }} color="primary" />
                                        )}
                                    </Box>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          );
                        }
                      )}
                  </Grid>
                  <Typography variant="h6" gutterBottom>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}


export const MeshStock = observer(MeshStockAC);
