import { observer } from 'mobx-react';
import { Box, Grid, IconButton, styled } from '@mui/material';
import ChatContent from '../../Messenger/ChatContent';
import { useEffect, useState } from 'react';
import { useDependency } from '../../../injections';
import { Controller } from '../../../mesh/controller';

const RootWrapper = styled(Box)(
  ({ theme }) => `
       height: calc(100vh - ${theme.header.height});
       display: flex;
`
);

const ChatWindow = styled(Box)(
  () => `
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
`
);

const Sidebar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open'
})<{
  open: boolean;
}>(
  ({ theme, open }) => `
        width: ${open ? '300px' : '0'};
        transition: width 0.3s ease;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
`
);

const TabButtons = styled(Box)(
  ({ theme }) => `
        display: flex;
`
);

const TabButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active'
})<{
  active: boolean;
}>(
  ({ theme, active }) => `
        flex: 1;
        padding: ${theme.spacing(1)};
        text-align: center;
        cursor: pointer;
        border-right: 1px solid white;
        &:last-child {
          border-right: none;
        }
`
);

const TabContent = styled(Box)(
  ({ theme }) => `
        flex: 1;
        padding: ${theme.spacing(2)};
        overflow-y: auto;
`
);


function BeckGPTAC() {
  const c = useDependency<Controller>(Controller);

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState('history');
  const [updateSignal, setUpdateSignal] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleLoad = async (id: string, title: string) => {
    await c.loadChat(id);
    c.store.activeChatId = id;
    c.store.activeChatName = title;
    setUpdateSignal((prev) => prev + 1);
  };

  const handleLoadItem = async (title: string, prompt: string) => {
    if (loading || prompt.trim() === '') return;
    setLoading(true);

    c.store.addDataToChat = false;
    c.store.messages = []; // Chat zurücksetzen
    c.store.activeChatId = undefined;
    c.store.activeChatName = title;
    const message = {
      role: 'user',
      content: prompt
    };
    await c.chat(message);
    setUpdateSignal((prev) => prev + 1);
    setLoading(false);
  };

  useEffect(() => {
    if (c.store.chats.length == 0) {
      c.getChats();
    }
  }, [c.store.chats]);

  useEffect(() => {
    if (c.store.onboardingItems.length == 0) {
      c.getOnboardingItems();
    }
  }, [c.store.onboardingItems]);

  return (
    <Grid display={'flex'}>
      <Box flex={1} style={{ width: '80%', position: 'relative' }}>
        <h2>{c.store.activeChatName.replaceAll('"', '')}</h2>
        <RootWrapper>
          <ChatWindow>
            <div
              style={{
                pointerEvents: loading ? 'none' : 'auto', // Verhindert Klicks
                opacity: loading ? 0.5 : 1, // Ausgrauen des Inhalts
                transition: 'opacity 0.3s', // Sanfter Übergang
              }}
            >
              <ChatContent updateSignal={updateSignal} />
            </div>
          </ChatWindow>


        </RootWrapper>
      </Box>
      {localStorage.getItem('azure') === 'true' && (
        <Grid display={'flex'}>
          <Box flex={1}>
            <IconButton onClick={() => setSidebarOpen(!sidebarOpen)}
                        className={'iconButton'}
                        style={{
                          marginLeft: '10px',
                          textDecoration: 'none',
                          top: '50%',
                          width: '48px', // Ensure consistent styling in case theme.spacing is unavailable
                          height: '48px',
                          border: '1px solid white',
                          borderRadius: '50%',
                          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                        }}
            >
              {sidebarOpen ? '>' : '<'}
            </IconButton>
          </Box>
          <Box flex={1}>
            <Sidebar open={sidebarOpen}>
              <TabButtons>
                <TabButton
                  active={activeTab === 'history'}
                  onClick={() => setActiveTab('history')}
                >
                  History
                </TabButton>
                <TabButton
                  active={activeTab === 'businessAnalyst'}
                  onClick={() => setActiveTab('businessAnalyst')}
                >
                  Knowledge Base
                </TabButton>
              </TabButtons>
              <TabContent>
                {activeTab === 'businessAnalyst' &&
                  <div style={{ height: '1px' }}>
                    {c.store.onboardingItems.map((item, index) => (
                      <div key={index}>
                        <IconButton
                          className={'iconButton'}
                          onClick={() => handleLoadItem(item.title, item.prompt)}
                        >
                          {(() => {
                            const isSubItem = /^\d+\.\d+$/.test(item.index); // Regex Prüfung

                            return (
                              <div
                                style={{
                                  width: '100%',
                                  fontSize: '14px',
                                  textAlign: 'left',
                                  paddingLeft: isSubItem ? '10px' : '0px'
                                }}
                              >
                                {item.index + ' ' + item.title}
                              </div>
                            );
                          })()}
                        </IconButton>
                      </div>
                    ))}
                  </div>


                }
                {activeTab === 'history' && (
                  <div style={{ height: '1px' }}>
                    {c.store.chats.map((chat, index) => (
                      <div key={index}>
                        <IconButton className={'iconButton'}
                                    onClick={() => handleLoad(chat.id, chat.name)}
                        >
                          <div>
                            <div style={{ width: '100%', fontSize: '20px' }}>
                              {chat.name.replaceAll('"', '')} </div>
                            <div style={{
                              width: '30%',
                              fontSize: '14px'
                            }}>
                              {new Date(chat.date).toLocaleDateString('de-DE')}</div>
                          </div>
                        </IconButton>
                      </div>
                    ))}
                  </div>
                )}
              </TabContent>
            </Sidebar>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export const BeckGPT = observer(BeckGPTAC);
